<template>
    <a-layout>
        <a-layout-sider v-model:collapsed="collapsed" :trigger="null" collapsible>
            <mymenu />
        </a-layout-sider>
        <a-layout>
            <a-layout-content :style="{ margin: '24px 16px', padding: '24px', background: '#fff', minHeight: '280px' }">
                <!-- 内容填充区 -->
                <a-dropdown>
                    <a-form-item label="角色类型" v-bind="cate">
                        <a-select v-model:value="node" placeholder="{{formState.rid}}">
                            <a-select-option value="1" @click="get_permissionlist">广告主</a-select-option>
                            <a-select-option value="2" @click="get_permissionlist">运营后台</a-select-option>
                            <a-select-option value="3" @click="get_permissionlist">流量平台</a-select-option>
                        </a-select>
                    </a-form-item>
                </a-dropdown>
                <a-checkbox-group v-model:value="permissionlist" name :options="checkAll" @change="updeta_check" />
            </a-layout-content>
        </a-layout>
    </a-layout>
</template>

<script>
import menu from "./menu.vue";
export default {
    components: {
        mymenu: menu
    },
    data() {
        return {
            key: "value",
            permissionlist: [],
            node: '1',
            checkAll: [],
            list1:[],
        }
    },
    methods: {
        aaa(arr1, arr2, cate) {
            for (var i = 0; i < arr1.length; i++) {
                var isnot = true
                for (var j = 0; j < arr2.length; j++) {
                    if (arr2[j] == arr1[i]) {
                        isnot = false
                        break
                    }
                }
                if (isnot == true) {
                    this.list1 = arr1
                    this.myaxios('/menu_role/', "put", { "rid":this.node,'name': arr1[i], 'cate': cate, "node": this.node }).then((res) => {
                        console.log(res);
                    }).catch((err) => {
                        console.log(err);
                    });
                    break
                }
            }
        },
        updeta_check() {
            if (this.permissionlist.length < this.list1.length) {
                this.aaa(this.list1, this.permissionlist, 0)
                console.log("进入");
            } else {
                this.aaa(this.permissionlist, this.list1, 1)
            }
        },
        get_role_permission() {
            this.myaxios("/menu_role/", "post", {
                "rid": this.node
            }).then((res) => {
                if (res.errcode == 0) {
                    this.permissionlist = res.data
                    this.list1 = res.data
                    console.log("permissionlist===>", this.permissionlist);
                } else {
                    this.$message.info(res.message)
                }
            })
        },
        get_permissionlist() {
            this.myaxios("/menu_role/", "get", {
            }).then((res) => {
                if (res.errcode == 0) {
                    // this.permissionlist = res.data.menu
                    this.get_role_permission();
                    this.checkAll = res.data.node_list
                    // this.$toast.success(result.message);

                    // 获取当前下拉框中角色拥有的权限
                    // this.list1 = result.data.checklost
                } else {
                    this.$message.info(result.message)
                }
            })
        }
    },
    mounted() {

    },
    created() {
        this.get_permissionlist();
    }
}
</script>

<style scoped>
@import url('../assets/style.css');
</style>